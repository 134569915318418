import { defineStore } from "pinia";

import { type User, UserRole } from "@/types/user";

export interface Register {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  address: string;
  usdot: string;
  timezoneId: string;
  phoneNumber: string;
  password: string;
  rePassword: string;
  agreeMe: boolean;
  [key: string]: string | boolean;
}

export const useAuthStore = defineStore({
  id: "user",
  state: () => {
    return {
      registerData: {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        address: "",
        usdot: "",
        timezoneId: "",
        phoneNumber: "",
        password: "",
        rePassword: "",
        agreeMe: false,
      } as Register,
      user: null as User | null,
      registerErrors: null as null | Record<string, string>,
      accessToken: null as string | null,
    };
  },
  getters: {
    isLoggedIn: (state) => {
      return !!state.accessToken && !!state.user;
    },
    isManager: (state) => {
      return state.user?.role === UserRole.Manager;
    },
    isAssistant: (state) => {
      return state.user?.role === UserRole.Assistant;
    },
    isAuditor: (state) => {
      return (
        state.user?.role === UserRole.Manager ||
        state.user?.role === UserRole.Assistant
      );
    },
    isAdmin: (state) => {
      return state.user?.role === UserRole.Superadmin;
    },
  },

  actions: {
    addInfo(data: Register) {
      Object.keys(data).forEach((key: string) => {
        this.registerData[key] = data[key];
      });
    },
    logOut() {
      this.user = null;
      this.accessToken = null;
    },
    clearErrors() {
      this.registerErrors = null;
    },

    clearUserInfo() {
      this.registerData.firstName = "";
      this.registerData.lastName = "";
      this.registerData.email = "";
      this.registerData.companyName = "";
      this.registerData.address = "";
      this.registerData.usdot = "";
      this.registerData.timezoneId = "";
      this.registerData.phoneNumber = "";
      this.registerData.password = "";
      this.registerData.rePassword = "";
      this.registerData.agreeMe = false;
    },
  },
  persist: true,
});
