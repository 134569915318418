<script setup lang="ts">
import { useHomeURL } from "@/composables/homeUrl";

defineEmits<{
  (e: "reset-error"): void;
}>();

const homeUrl = useHomeURL();

const handleRefreshPage = () => {
  window.location.reload();
};
</script>

<template>
  <div
    class="w-screen h-screen fixed inset-0 bg-[var(--el-bg-color-page)] z-50 flex flex-col items-center justify-center"
  >
    <ErrorIcon class="text-primary w-1/2 max-w-2xl max-h-[40vh]" />
    <h1 class="mt-8">Aaaah! Something went wrong</h1>
    <p>Please, try again later or refresh the page</p>
    <div class="mt-4 flex">
      <router-link
        v-if="homeUrl"
        v-slot="{ href }"
        custom
        :to="$getRouterTo(homeUrl)"
      >
        <el-button type="primary" tag="a" class="no-underline" :href="href">
          Go to homepage
        </el-button>
      </router-link>
      <el-button @click="handleRefreshPage">Refresh</el-button>
    </div>
  </div>
</template>
