import { abilitiesPlugin } from "@casl/vue";
import { QueryCache, QueryClient, VueQueryPlugin } from "@tanstack/vue-query";
import { createHead } from "@vueuse/head";
import { type AxiosError, isAxiosError } from "axios";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";

import { ROUTE_NAMES as AUTH_ROUTE_NAMES } from "@/modules/auth";
import { useAuthStore } from "@/stores/auth";
import { HttpStatusCode } from "@/types/common";

import App from "./App.vue";
import router from "./router";
import { ability } from "./services/ability";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
const head = createHead();

// Register plugins
const plugins = import.meta.globEager("./plugins/*.ts");

for (const path in plugins) {
  plugins[path].default({ app, router, head });
}

app.use(router);

const isAxiosErorr = (error: unknown): error is AxiosError => {
  return error !== null && typeof error === "object" && "response" in error;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: import.meta.env.PROD,
      retry(failureCount, error) {
        if (
          isAxiosErorr(error) &&
          error.response?.status === HttpStatusCode.FORBIDDEN
        ) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error: unknown) => {
      if (isAxiosError(error)) {
        if (error.response?.status === HttpStatusCode.FORBIDDEN) {
          router.push({ name: AUTH_ROUTE_NAMES.Login });
          const authStore = useAuthStore();
          authStore.logOut();
        } else if (
          error.response?.status !== HttpStatusCode.UNPROCESSABLE_ENTITY
        ) {
          ElNotification({
            title: error.message,
            type: "error",
          });
        }
      }
    },
  }),
});
app.use(VueQueryPlugin, { queryClient });
app.use(pinia);
app.use(abilitiesPlugin, ability, { useGlobalProperties: true });

app.mount("#app");
